
/* Add styles for the theme toggle button */
.theme-toggle-button {
  position: fixed !important;
  bottom: 20px;
  right: 0vh;
  background-color: transparent;
  border: none;
  font-size: 2rem; /* Adjust size as needed */
  cursor: pointer !important;
  color: inherit;
  /* position: relative !important; */
  overflow: hidden;
  z-index: 999;
}

/* Wave animation styles */
.theme-toggle-button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: rgba(0, 0, 0, 0.2); /* Change color to fit your theme */
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  animation: none; /* Default to no animation */
}

/* Keyframes for wave animation */
@keyframes wave-animation {
  to {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}

/* Add class to trigger animation */
.theme-toggle-button.wave-animation::before {
  animation: wave-animation 0.6s linear;
}

/* Icon color styles */
.sun-icon {
  color: #fff;
}

.moon-icon {
  color: #014A76;
}


/* Default Light Mode Styles */
body {
  background-color: white;
  color: black;
}

/* Dark Mode Styles */
body.dark-mode {
  background-color: black;
  color: white;

  h1,h2,h3,h4,h5,h6,p,ul,li{
    color: #fff;
  }

  .footer {
    background: #b2d4f6;
    color: #014A76 !important;

    p{
    color: #014A76 !important;

    }
  }
  .navbar{
    background-color:black ;
  }
  .navbar-menu a ,.social-icons a ,.call-back{
    color: #fff;
    border-color: #fff;
  }
  .contact-form {
    background-color: #000000;
    border-color: black;
  }
  .hamburger .bar ,  .close-btn,.hamburger{
    color: #fff !important;
  }
}

@media (max-width: 768px) {
  body.dark-mode {

    .navbar-menu{
      background-color:black ;
    }
    
  }
}

/* Additional styles for dark mode */
header {
  background-color: inherit; /* Will change according to mode */
  color: inherit;
}

nav a {
  color: inherit; /* Link color will also adapt */
}

  
  
  /* Home Page css start  */

  .carousel-control-prev , .carousel-control-next{
    display: none;
  }

  .container{

    padding: 2%;

  }

.herobanner {

  img{
    width: 100%;
    height: 100vh;
}

  

}

.bluecolor{
  color: #2980b9  ;
}

.btnbanner{
  background-color: #014A76;
  text-decoration: none;
  color: #fff !important;
  padding: 1% 2%;
  font-size: 2vh;
  /* border-radius:50px; */
  text-transform: uppercase;
}

.btnbanner:hover{
  background-color: #8FA9C2;
  color: #ffffff !important;
}
.herosec-container{


  img{
    width: 100%;
    height: 100vh;
  }
  .col-lg-6{
    padding:2%  3%;
    align-self: center;
  }
}

.box {

  padding: 12% 14%;
  border: 1px solid #8FA9C2;
  height: 100%;
  color: black;


  img{
    width: 10vh;
  }

}
.box:hover{
  background-color: #014A76;
  color: #fff;
  transition: .6s;
}


  /* Home Page css end  */


  /* About css Starts */

  .aboutbg{
    background:url("../src/assets/images/about/aboutbanner.jpg");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    height: 70vh;
    align-content: center;
    color: #fff;
  }

  .aboutsec1bg{
    background:url("../src/assets/images/about/aboutsecimg.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: 70vh;
    align-content: center;
    color: #fff;
  }

  .aboutbox{
    border: 1px solid #8FA9C2;
  }

  .aboutbox:hover{
    background-color: #014A76;
  color: #fff;
  transition: .4s;
  }

  

  /* About css Ends */


  /* Service css Starts */
.servicebg{
  background:url("../src/assets/images/service/servicebg.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  height: 70vh;
  align-content: center;
  color: #fff;
}

.allservices {

  align-items: center;

  img{
    width: 15vh;
  }
}
.servicebox{
  border: 1px solid #8FA9C2;
  

  .row{
    padding: 1% 6%;
  }
  ul{
    list-style: circle;
    list-style-image: url("../src/assets/images/pointer.png");
    
  }
}
.servicebox:hover{
  background-color: #014A76;
  color: #fff;
  transition: .4s;
}

  /* Service css Ends */


  /* Contact css Start */

  .contactbg{
    background:url("../src/assets/images/contact/contactbg.jpg");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    height: 70vh;
    align-content: center;
    color: #fff;
  }
  .contact-form {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 0px;
    background-color: #f9f9f9;
  }
  
  .form-group-inline {
    display: flex; /* Use Flexbox to place fields inline */
    gap: 20px; /* Add some space between the fields */
    margin-bottom: 15px; /* Space below the group */
  }
  
  .form-field {
    flex: 1; /* Make the fields equal width */
  }
  
  .contact-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: 400;
  }
  
  .contact-form input,
  .contact-form select,
  .contact-form textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 0px;
    font-size: 14px;
  }
  
  .contact-form textarea {
    height: 15vh;
  }
  
  .contact-form button {
    background-color: #8FA9C2;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }
  
  .contact-form button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
  
  .error {
    color: red;
    font-size: 12px;
  }
  

  /* Contact css Ends */



  /* mobile css starts */

  @media (max-width: 768px) {

    .container{
      padding: 6%;

    }
    
    /* Home css starts  */

    .herobanner {

      img{
        height: 80vh;
    }
  }

  .btnbanner{
    padding: 1.5vh 5vh;
    margin-top: 40%;
  }

  .herosec-container{


    img{
      height: 60vh;
    }
   
 

  }
  hr{
    padding: 1%;
    width: 90%;
  }

  /* Home css ends  */

/* About css start  */

.aboutbg ,  .aboutsec1bg ,.servicebg  {
  height: 60vh;
  background-size: 100% 60%;
}
.contactbg{
  background-size: cover;
  height: 60vh;

}

}



  /* mobile css Ends */
